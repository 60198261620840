import React from "react"
import Slider from 'react-slick'
import { Link } from 'gatsby'

import Chris1 from '../../images/chris/chris1.jpg'
import Chris2 from '../../images/chris/chris2.jpg'
import Chris3 from '../../images/chris/chris3.jpg'
import Chris4 from '../../images/chris/chris4.jpg'
import Chris5 from '../../images/chris/chris5.jpg'
import Chris6 from '../../images/chris/chris6.jpg'



const Hero = () => {
    const settings = {
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 6000,
        dots: true,
        arrows: false,
        fade: true,
        cssEase: 'linear'        
    }

    return (
        <Slider {...settings} className="me-slider">
            <div className="me-slide">
                <div className="me-content">
                    <h2>I am a Full-Stack Web Developer</h2>
                    <p>Hi, my name is Chris Bartlett and I am a Full-Stack Web Developer with over 20 years of experience. I live and work in Hong Kong at a top Web Design agency and also freelance in my spare time. Feel free to <Link to="#get-in-touch">Get in touch</Link>.</p>   
                </div>
                <div className="me-image">
                    <img src={Chris1} alt="Chris Bartlett Full-Stack Web Developer" id="c1" />
                </div>
            </div>

            <div className="me-slide">
                <div className="me-content">
                    <h2>Fully responsive websites</h2>
                    <p>I can build fast responsive websites that work on Desktops, Tablets and mobiles at a fraction of the cost of design agencies, but with the same quality. If you would like me to build your website please feel free to <Link to="#get-in-touch">Get in touch</Link>.</p>   
                </div>
                <div className="me-image">
                    <img src={Chris2} alt="Chris Bartlett Full-Stack Web Developer" id="c2" loading="lazy" />
                </div>
            </div>
            
            
            <div className="me-slide">
                <div className="me-content">
                    <h2>Would you like a website <br/>but not sure what you need?</h2>
                    <p>Let me know your business needs and I can recommend which type of website you need.  Please <Link to="#get-in-touch">Get in touch</Link>.</p>   
                </div>
                <div className="me-image">
                    <img src={Chris3} alt="Chris Bartlett Full-Stack Web Developer" id="c3" loading="lazy" />
                </div>
            </div>
            
            
            <div className="me-slide">
                <div className="me-content">
                    <h2>Already have a Design made <br/>or need help with Design too?</h2>
                    <p>I can build you a website from your designer's design or create a custom package with design included from the many great contacts from all over the world. Feel free to <Link to="#get-in-touch">Get in touch</Link>.</p>   
                </div>
                <div className="me-image">
                    <img src={Chris4} alt="Chris Bartlett Full-Stack Web Developer" id="c4" loading="lazy" />
                </div>
            </div>
            
            
            <div className="me-slide">
                <div className="me-content">
                    <h2>1-Pager, Corporate Site, <br/>Ecommerce site, Blog...</h2>
                    <p>There are many different types of websites that can be built and I will sugguest the best one for your budget and needs. To discuss your business' needs <Link to="#get-in-touch">Get in touch</Link>.</p>  
                </div>
                <div className="me-image">
                    <img src={Chris5} alt="Chris Bartlett Full-Stack Web Developer" id="c5" loading="lazy" />
                </div>
            </div>
            
            
            <div className="me-slide">
                <div className="me-content">
                    <h2>Rest assured when you work with me</h2>
                    <p>I will build you a great website with the agreed functionalities in a timely manner. Let us work together to develop a top website wherever you are in the world - UK, Europe, United States, Canada or Asia. If you are intersted in hiring me for freelance work please <Link to="#get-in-touch">Get in touch</Link>.</p>   
                </div>
                <div className="me-image">
                    <img src={Chris6} alt="Chris Bartlett Full-Stack Web Developer" id="c6" loading="lazy" />
                </div>
            </div>                    
        </Slider>                
    )
}


export default Hero