import React from "react"
import 'react-slick'
import { Waypoint } from 'react-waypoint';

import Layout from "../components/layout/Layout"
import Hero from "../components/home/Hero"
import WhatIdo from "../components/home/WhatIdo"
import PortfolioSelection from "../components/portfolio/PortfolioSelection"
import ContactSection from "../components/contact/ContactSection"
import SEO from "../components/seo"

import {handleWaypointIn, handleWaypointOut} from "../utilities/waypoint"
import { Helmet } from "react-helmet";

const Index = () => {

    
    return (         
        <div className="home">
            <Helmet
                meta={[
                    { name: 'google-site-verification', content: 'jlsQprQC3dSlvGd3PGmOOYaRAd-uJ51GygODPkZgUIU' },
                    { name: 'yandex-verification', content: '09beca7ed8f8016d' },
                ]}
                
            />     
            <Layout>
                <SEO 
                    title="Full-Stack Web Developer"
                />	             
                <section className="hero-wrapper">
                    <div className="content-wrapper">
                        <h1 className="logo"><span className="c">C</span><span className="h">h</span><span className="r">r</span><span className="i">i</span><span className="s">s</span> <span className="sb">B</span><span className="sa">a</span><span className="sr">r</span><span className="st">t</span><span className="sl">l</span><span className="se">e</span><span className="stt">t</span><span className="sttt">t</span></h1> 
                        <Hero />
                    </div>
                </section> 

                <section className="what-i-do section-padding-medium">
                    <div className="container">
                        <Waypoint 
                            onEnter={() => handleWaypointIn('how-can-help-h2')}
                            onLeave={() => handleWaypointOut('how-can-help-h2')}
                        >
                            <h2 id="how-can-help-h2">How can I help you ?</h2> 
                        </Waypoint>
                        <WhatIdo />
                    </div>                
                </section>  

                <section className="portfolio">
                    <Waypoint 
                        onEnter={() => handleWaypointIn('portfolio-h2')}
                        onLeave={() => handleWaypointOut('portfolio-h2')}
                    >                
                        <h2 id="portfolio-h2">Portfolio</h2> 
                    </Waypoint>
                    <PortfolioSelection portfolioNumber="6" />               
                </section>   
                                  
                <ContactSection />                                         
            </Layout> 
        </div>
    )
}

export default Index