import React from "react"
import Slider from 'react-slick'

const WhatIdo = () => {
    const settings = {
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 6000,
        dots: false,
        arrows: true,
        responsive: [
          {
            breakpoint: 1220,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
            }
          },
          {
            breakpoint: 850,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            }
          }
        ]
    }

    return (    
    <Slider {...settings}  className="services-slider">
        <div className="service-slide">        
            <span className="icon-responsive-device"></span>
            <h5>Responsive Websites</h5>
            <p>From mobiles to Desktops, responsive web design makes your website accessible to all users, regardless of their device.</p>
        </div>

        <div className="service-slide">
            <span className="icon-data-management"></span>
            <h5>Content Management</h5>
            <p>From Wordpress to Drupal to Custom Solutions. I'll help you easily update content through a Content Management Systems (CMS) without knowing how to code.</p>
        </div>
        <div className="service-slide">        
            <span className="icon-market-monitoring"></span>
            <h5>Analytics</h5>
            <p>I'll help you set up Google Analytics to gain insights into your website metrics so that you can make smarter business decisions.</p>
        </div>
        <div className="service-slide">
            <span className="icon-programming"></span>
            <h5>Web Development</h5>
            <p>I can help you build you a fast and secure website. From one page sites to small corporate sites to magazines to shops. I can help you with your web development needs. </p>
        </div>        

        <div className="service-slide">
            <span className="icon-ecommerce-website"></span>
            <h5>Ecommerce  Websites</h5>
            <p>Do you want to sell something online? I can help you setup your shop and payment system so you can start making money fast! I build Woocommerce and Shopify ecommerce solutions.</p>
        </div>
        
        <div className="service-slide">
            <span className="icon-server-admin"></span>
            <h5>Managed Web Hosting</h5>
            <p>I can help setup your hosting / server(s) and offer monthly managed service. I can also host your websites for you.</p>
        </div>  
        
        <div className="service-slide">
            <span className="icon-development-team"></span>
            <h5>Partnerships</h5>
            <p>Are you a Web Designer or Web Design Agency looking for a freelance partner to help you with Web Development? Or looking to outsource your development work? If so I can help you with your needs. </p>
        </div>  
        
        <div className="service-slide">
            <span className="icon-client-service"></span>
            <h5>Around the World</h5>
            <p>From US/Canada to UK/Europe to Hong Kong/Asia - it doesn't matter where you are in the world I am happy to help you build your website for you. </p>
        </div>          
    </Slider>
    )
}
  

  export default WhatIdo