import React from "react"
import { StaticQuery, Link, navigate } from 'gatsby'
import Tilt from 'react-parallax-tilt';
import Img from "gatsby-image"
import { Waypoint } from 'react-waypoint';

import {handleWaypointIn} from "../../utilities/waypoint"

import { shuffle } from "../../utilities/shuffle"

const PortfolioSelection = ({ portfolioNumber = 12 }) => {

    const goToPortfolio = () => {
        navigate('/portfolio') 
    }
    return ( 
        <StaticQuery
        query={graphql `
        query {
        allMarkdownRemark(sort: {order: ASC, fields: [frontmatter___title]}, filter: {frontmatter: {post_type: {eq: "portfolio"}}}) {
          edges {
            node {
              fields {
                slug
              }
              frontmatter {
                  title
                  post_type
                  thumbnail {
					childImageSharp {
						fixed(height: 680, quality: 90) {
							...GatsbyImageSharpFixed_withWebp
						}						
					}  
                  }            
              }
            }
          }
        }
      }
      `}
        render={data => {
            let portfolios = data.allMarkdownRemark.edges

            portfolios = shuffle(portfolios).slice(0, portfolioNumber)
            return (
            <>
                <div className="container"> 
                    {
                        portfolios.map((portfolio, index) => {
                            return ( 
                                <Tilt 
                                    scale={1.15}
                                    transitionSpeed={2500}     
                                    key={index}  
                                >
                                        <Link 
                                            key={index}
                                            to={`/${portfolio.node.frontmatter.post_type}${portfolio.node.fields.slug}`} 
                                        >
                                            {portfolio.node.frontmatter.thumbnail && 
                                                <div className="portfolio-image">
                                                    <Img 
                                                        fixed={portfolio.node.frontmatter.thumbnail.childImageSharp.fixed} 
                                                        alt={portfolio.node.frontmatter.title} 
                                                        key="header-thumb" 
                                                        objectFit="cover"
                                                        objectPosition="top center"																						
                                                    />                                                   
                                                </div>
                                            }
                                            <div className="portfolio-content">
                                                <h3 className="text">{portfolio.node.frontmatter.title}</h3> 
                                            </div>                            
                                        </Link> 
                                </Tilt>
                            )
                        })
                    }             
                </div>
                <div className="all-wrapper">
                    <Waypoint onEnter={() => handleWaypointIn('port-button', 'jello-vertical')}>         
                        <div id="port-button">                    
                            <button className="button-black" onClick={goToPortfolio}>
                                View All Portfolio
                            </button>   
                        </div>    
                    </Waypoint>                      
 
                </div>     
            </>
        )}}
      />
    )
}
  

export default PortfolioSelection